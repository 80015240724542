import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import { Link } from "gatsby"
import Icon from '../components/icons';
import Vimeo from '@u-wave/react-vimeo';
import { withPrefix } from 'gatsby'


import resilience from '../_images/Titans-of-Resilience.svg';

import isaacAlferos from '../_images/landing-pages/isaac-alferos/isaac-alferos-hero-image-small.jpg';
import AnLe from '../_images/landing-pages/an-le/an-le-hero-small.jpg';
import KimberlyHardaway from '../_images/landing-pages/kimberly-hardaway/kimberly-hardaway-hero-small.jpg';
import KimberlyCruz from '../_images/landing-pages/kimberly-cruz/kimberly-cruz-hero-small.jpg';
import IrwinGill from '../_images/landing-pages/irwin-gill/irwin-gill-hero-small.jpg';

Modal.setAppElement('#___gatsby')

export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logger = this.logger.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }
  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }

  render() {
    var pageTitle = 'Titan of Resilience';

    return (
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="theme-landing-page" />

          {/* <meta name="description" content={pageDesc} /> */}

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          {/* <meta itemprop="description" content={pageDesc} /> */}
          {/* <meta itemprop="image" content={socialBanner} /> */}

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          {/* <meta name="twitter:description" content={pageDesc} /> */}
          {/* <meta name="twitter:image:src" content={socialBanner} /> */}

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          {/* <meta property="og:image" content={socialBanner} /> */}
          <meta property="og:image:alt" content={pageTitle} />
          {/* <meta property="og:description" content={pageDesc} /> */}
        </Helmet>


        <section className="hero resilience-hero">
          <div className="wrap small" id="hero-text">
            <h1><img src={resilience} alt={pageTitle} /></h1>
            <p>
            Titans press forward or pivot with grit and agility to persevere through difficulties, challenges or the unknown.
            </p>

            <VideoModal vimeoID={670372740} />
          </div>
          <div className="tint" />
          <video id="heroVideo" autoPlay loop muted="muted"  title="Intro Video">
            <source src={ withPrefix('/_video/resilience.webm') } type="video/webm; codecs=vp9,vorbis" />
            <source src={ withPrefix('/_video/resilience.mp4') } type="video/mp4" />
            <track kind="descriptions"  src={ withPrefix('/_video/resilience.webm') } />
          </video>
        </section>

        <section className="profile-links">
          <ul>
            <li><Link to="/resilience/irwin-gill" className="profile-link" style={{ backgroundImage: `url(${IrwinGill})` }}><span className="profile-link-text">Irwin Gill</span></Link></li>
            <li><Link to="/resilience/kimberly-cruz" className="profile-link" style={{ backgroundImage: `url(${KimberlyCruz})` }}><span className="profile-link-text">Kimberly Cruz</span></Link></li>
            <li><Link to="/resilience/isaac-alferos" className="profile-link" style={{ backgroundImage: `url(${isaacAlferos})` }}><span className="profile-link-text">Isaac Alferos</span></Link></li>
            <li><Link to="/resilience/an-le" className="profile-link" style={{ backgroundImage: `url(${AnLe})` }}><span className="profile-link-text">An Le</span></Link></li>
            <li><Link to="/resilience/kimberly-hardaway" className="profile-link" style={{ backgroundImage: `url(${KimberlyHardaway})` }}><span className="profile-link-text">Kimberly Hardaway</span></Link></li>
          </ul>
        </section>

        <section className="content">
          <div className="boiler-plate">
            <h2>About the Campaign</h2>
            <p>“It Takes a Titan: The Campaign for Cal State Fullerton,” is the university’s first-ever comprehensive philanthropic campaign. The $250 million initiative prioritizes investing in projects that enhance academic innovation, empower students, transform campus structures and enrich the community. It is an opportunity for the entire Titan family – faculty, staff, alumni and community members - to be the difference in elevating the potential of our remarkable students.</p>
            <Link to="/about" className="buttonBlue">Learn More</Link>
          </div>
          <div className="sidebar">
            <div className="give-cta">
              <p>Be the difference in a Titan's life.</p>
              <a href="https://give.fullerton.edu/titan-fund" className="button orange">Support Our Campaign</a>
            </div>
            <div className="other-cta">
              
              <a href="http://admissions.fullerton.edu/" className="buttonBlue outline">Learn More About Admissions</a>
              
              <button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button>

              <br />
              <h3>Stay Connected</h3>

              <ul className="social-links">
                <li><a className="facebook" href="http://www.facebook.com/csufofficial"><Icon name="facebook" alt="Facebook" /></a></li>
                <li><a className="twitter" href="http://twitter.com/csuf"><Icon name="twitter" alt="Twitter" /></a></li>
                <li><a className="instagram" href="http://instagram.com/csufofficial"><Icon name="instagram" alt="Instagram" /></a></li>
                <li><a className="linkedin" href="http://www.linkedin.com/edu/california-state-university-fullerton-17827"><Icon name="linkedin" alt="Linkedin" /></a></li>
              </ul>

            </div>
          </div>
        </section>

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="resilience" college="Cal State Fullerton" />
          </div>
        </Modal>
        
      </Layout>
    );
  }
}

export class VideoModal extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Watch Video'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center' }}>
          <button id="playVideo" className="button orange" onClick={this.openModal}>
            Watch Video <svg className="icon xs" style={{ verticalAlign: 'baseline' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title>Watch Video</title><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/></svg>
          </button>
        </div>
        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Watch Video"
          className="videoModal"
          overlayClassName="videoOverlay"
          shouldCloseOnOverlayClick={true}
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap-video">
            <Vimeo
              video={this.props.vimeoID}
              responsive
              showTitle="false"
              showByline="false"
              showPortrait="false"
              autoplay
              onEnd={this.closeModal}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
